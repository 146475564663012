import React from 'react'
import photo from '../andrew.png'
import { Link } from 'react-router-dom'

const About = () => {
    return (
        <div className='container mt-5'>
            <div className='row'>
                <div className='col-lg-8'>
                    <h1 className='h1_before'>About Me</h1>
                    <h2 className='mt-4'> Hi, my name is Andrew👋!</h2>
                    <p>I am a <strong>Computer Science and Information Systems graduate</strong> from the <strong>University of Sydney</strong>, with a passion for designing and developing scalable, secure, and user-friendly web applications that solve complex problems. My proficiency in <strong>HTML, CSS, JavaScript, Python, React</strong>, and responsive web design, along with my resourceful problem-solving skills, makes me a valuable addition to any team.</p>
                    <p>I’ve worked on projects as a Web Developer at BenimbleIT, where I optimized SEO, enhanced website functionality, and improved the user interface. Additionally, I have hands-on experience as a Coding AI Trainer, ensuring the quality and efficiency of AI-generated code.</p>
                    <p>With a passion for innovation and problem-solving, I’m excited to contribute my skills and experiences to a dynamic team to create web applications that make an impact.</p>
                    <Link to="/about"><button className='btn btn-primary'>Read more {"=>"}</button></Link>
                </div>
                <div className='col-lg-4'>
                    <img src={photo} style={{ width: "100%", height: "auto" }} className='profile-container' alt="Profile" />
                </div>
            </div>
        </div>
    )
}

export default About
