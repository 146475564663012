import React, { useEffect } from 'react'
import photo from '../andrew.png'
import SkillCard from './SkillCard'

const AboutPage = () => {
    let language_list = ["Python", "Java", "Javascript", "HTML", "CSS"]
    let tools_list = ["Git", "VSCode", "Linux", "Figma"]
    let database_list = ["MySQL", "MongoDB"]
    let framework_list = ["React", "Node.js", "Express.js", "Flask", "Django"]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='container'>
            <h1 className='h1_slash'>About</h1>
            <p>Who am I?</p>
            <div className='row'>
                <div className='col-lg-4'>
                <img src={photo} style={{ width: "100%", height: "auto" }} className='profile-container' alt="Profile" />
                </div>
                <div className='col-lg-8 d-flex justify-content-center flex-column'>
                    <h2 className='mt-4'> Hi, my name is Andrew👋!</h2>
                    <p>I am a proud graduate of the <strong>University of Sydney</strong> with a degree in Computer Science and Information Systems. I’m passionate about designing and developing secure, scalable, and user-friendly web applications that solve complex problems. My proficiency in <strong>HTML, CSS, JavaScript, Python, React</strong>, and responsive web design, combined with my ability to rapidly learn new tools and technologies, makes me a valuable addition to any team.</p>
                    <p>In my recent experiences, I’ve worked as a Web Developer at BenimbleIT, where I transformed client ideas into fully functional websites, optimized SEO, and enhanced overall user experience. I’ve also worked as a Coding AI Trainer, ensuring high-quality AI-generated code, refining model responses, and gaining expertise across various programming languages.</p>
                    <p>I’m excited to bring my technical skills, creativity, and passion to your team, designing solutions that improve user experiences and drive efficiency in complex environments.</p>
                </div>
            </div>

            <div className='row mt-5'>
                <h1 className='h1_before'>Skills</h1>
                <div className='row d-flex'>
                    <SkillCard title="Tools" skill_list={tools_list}></SkillCard>
                    <SkillCard title="Databases" skill_list={database_list}></SkillCard>
                    <SkillCard title="Languages" skill_list={language_list}></SkillCard>
                    <SkillCard title="Frameworks" skill_list={framework_list}></SkillCard>
                </div>
            </div>

            <div className='row mt-5'>
                <h1 className='h1_before'>Fun Facts</h1>
                <p>When I'm not coding, you can find me exploring the latest in AI, playing guitar, or enjoying the vibrant city life of Sydney.</p>
            </div>
        </div>
    )
}

export default AboutPage
