import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import './Chat.css';

const Chat = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [chatVisible, setChatVisible] = useState(false);
    const messagesContainerRef = useRef(null);

    const [apiKey, setApiKey] = useState(process.env.REACT_APP_OPENAI_API_KEY);

    const OPENAI_API_ENDPOINT = 'https://api.openai.com/v1/chat/completions';

    const backgroundInfo = `
    You are a chatbot for Andrew Adisaputra's professional portfolio. Your tone should be third person, referring to Andrew, and you should use first person from an AI perspective.
    
    Andrew Adisaputra is a graduate with a Bachelor of Science in Computer Science and Information Systems from the University of Sydney, where he secured a high score in his Capstone project. He has hands-on experience delivering tailored IT solutions, web development, and optimizing infrastructures to improve reliability and scalability. 
    
    He currently works as a Part-Time Web Developer at BenimbleIT, where he revamped websites by assessing design, functionality, and SEO optimization. Previously, Andrew led a development team on a Capstone project at the Sydney Pharmacy School, creating a dynamic pharmacokinetics study tool that was praised for its usability and design.

    Andrew also excels as a Coding AI Trainer at Data Annotation Tech, focusing on training AI models in coding best practices and debugging. His technical skills include JavaScript, HTML, CSS, Python, Java, React.js, Django, and SQL. 
    
    Additionally, Andrew co-founded Gendigital Academy, where he taught digital literacy and Python programming to elementary students, receiving highly positive feedback from both parents and students.

    In his free time, Andrew enjoys playing guitar and learning new technologies. He is based in Sydney but open to relocation for new opportunities.
    
    **Resume Overview:**
    
    - **Education:** Bachelor of Science in Computer Science and Information Systems from the University of Sydney, graduated in December 2023.
      - Relevant courses: Data Structures & Algorithms, Distributed Systems, Web Information Systems, Intro to AI, Object-Oriented Programming.
      - Completed a highly regarded Capstone project, scoring 93/100, where he led a team of 7 in developing a dynamic web application for the Sydney Pharmacy School.
    
    - **Professional Experience:**
      - **Web Developer at BenimbleIT (July 2024 - Present):** 
        - Revamped the company's website by improving design, navigation, and SEO.
        - Developed WordPress sites with custom themes and optimized for user experience.
      - **Coding AI Trainer at Data Annotation Tech (May 2024 - Present):**
        - Trained AI models on coding best practices, enhancing JavaScript, Python, and HTML expertise.
        - Debugged and improved the AI’s coding quality through detailed evaluations.
      - **Capstone Project (July 2023 - Dec 2023):** 
        - Led a web development project for the Sydney Pharmacy School, creating a pharmacokinetics web application.
      - **Gendigital Academy Co-founder (2018-2020):**
        - Taught digital literacy and Python programming to elementary school students.
        - Received positive feedback from parents and students on the quality of instruction.
    
    - **Skills:**
      - Languages: JavaScript, Python, HTML, CSS, Java, React.js, Django, SQL.
      - Tools: WordPress, Git, Docker, VSCode, and others.
    
    Andrew is passionate about technology and applying his skills to IT and web development roles. He is based in Sydney but is open to relocation for future roles.

    **Contact:**
    - Portfolio: andrewadi.com
    - Email: Andrew.adisaputra1@gmail.com
    - LinkedIn: linkedin.com/in/andrew-adisaputra/
    `;

    useEffect(() => {
        const introMessage = {
            role: 'assistant',
            content: "Hello! This is Andrew's portfolio chatbot. I'm here to answer any questions you may have about Andrew Adisaputra. Feel free to ask about his skills, experience, projects, or anything else you'd like to know!"
        };
        setMessages([introMessage]);
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages, isTyping]);

    const scrollToBottom = () => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    };

    const simulateTyping = async (text) => {
        setIsTyping(true);
        let typed = '';
        for (let i = 0; i < text.length; i++) {
            typed += text[i];
            setMessages(prevMessages => {
                const updatedMessages = [...prevMessages];
                if (updatedMessages[updatedMessages.length - 1].role === 'assistant') {
                    updatedMessages[updatedMessages.length - 1].content = typed;
                } else {
                    updatedMessages.push({ role: 'assistant', content: typed });
                }
                return updatedMessages;
            });
            await new Promise(resolve => setTimeout(resolve, 10));
            scrollToBottom();
        }
        setIsTyping(false);
    };

    const handleSend = async () => {
        if (input.trim()) {
            const userMessage = { role: 'user', content: input };
            setMessages(prevMessages => [...prevMessages, userMessage]);
            setInput('');
            setIsTyping(true);

            try {
                const conversationHistory = [
                    { role: "system", content: backgroundInfo },
                    ...messages,
                    userMessage
                ];

                const response = await axios.post(
                    OPENAI_API_ENDPOINT,
                    {
                        model: "gpt-4o-mini",
                        messages: conversationHistory
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${apiKey}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                const aiResponse = response.data.choices[0].message.content;
                await simulateTyping(aiResponse);
            } catch (error) {
                console.error('Error with OpenAI API:', error);
                await simulateTyping("I apologize, but I'm having trouble connecting right now. Please try again later.");
            }
        }
    };

    return (
        <>
            <div className="chat-icon" onClick={() => setChatVisible(true)}>
                💬
            </div>

            {chatVisible && (
                <div className="chatbox-container">
                    <div className="chatbox">
                        <div className="chat-header">
                            <h3>Chat with Andrew's AI</h3>
                            <button className="close-button" onClick={() => setChatVisible(false)}>✕</button>
                        </div>
                        <div className="messages" ref={messagesContainerRef}>
                            {messages.map((message, index) => (
                                <div key={index} className={`message ${message.role === 'user' ? 'user' : 'ai'}`}>
                                    <ReactMarkdown>{message.content}</ReactMarkdown>
                                </div>
                            ))}
                            {isTyping && (
                                <div className="message ai">
                                    <p>
                                        <span className="typing-animation">
                                            <span className="typing-dot"></span>
                                            <span className="typing-dot"></span>
                                            <span className="typing-dot"></span>
                                        </span>
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="input-group">
                            <input
                                type="text"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                placeholder="Ask about Andrew..."
                                onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                            />
                            <button onClick={handleSend}>Send</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Chat;