import React from 'react';

const CareerHistory = () => {
  const careerItems = [
    {
      period: "July 2024 - Present",
      title: "Part Time Web Developer",
      company: "BenimbleIT",
      description: "Assessed and improved website design, functionality, and user experience. Developed a fully functional WordPress website with custom themes and plugins. Optimized for SEO and improved overall site performance."
    },
    {
      period: "May 2024 - Present",
      title: "Coding AI Trainer",
      company: "Data Annotation Tech",
      description: "Selected as a top worker (top 10%) for exceptional performance in training AI models on coding best practices. Focused on Javascript, HTML, CSS, Python, Java, and other languages as required."
    },
    {
      period: "July 2023 - December 2023",
      title: "Web Developer - Sydney Pharmacy School Capstone Project",
      company: "University Of Sydney",
      description: "Led development of a website for 1st-year pharmacy students to study pharmacokinetic equations. Used HTML, CSS, Javascript, React.js, and Bootstrap. Received commendations for functionality and user experience."
    },
    {
      period: "2018 - 2020",
      title: "Co-founder/Digital Literacy Tutor",
      company: "Gendigital Academy",
      description: "Co-founded a volunteer digital education program teaching programming foundations to 3rd-5th grade students using Python. Received positive feedback from students and parents."
    }
  ];

  return (
    <div className="career-history mt-5">
      <h2 className="career-title h1_before mb-3">Career History</h2>
      <div className="timeline">
        {careerItems.map((item, index) => (
          <div key={index} className="timeline-item mb-3">
            <h5 className="item-title">{item.title}</h5>
            <p className="item-company">{item.company}</p>
            <p className="item-period">{item.period}</p>
            <p className="item-description">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CareerHistory;