import React from 'react'

const Hamburger = () => {
    return (
        <div className='hamburger-div'>
            <div className='first-line'></div>
            <div className='second-line'></div>
        </div>
    )
}

export default Hamburger